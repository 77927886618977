import React from 'react';
import { ModalImage } from './ModalImage';
import Image from '@/components/atoms/image';

export interface IRModalImagesProps {
    className?: string;
    small: string;
    smallSrcSet?: string;
    medium?: string;
    large: string;
    alt?: string;
    title?: string;
    hideDownloadButton?: boolean;
    hideZoomButton?: boolean;
    hideRotateButton?: boolean;
    imageBackgroundColor?: string;
    onMouseEnter: (e: any) => void
    onMouseMove: (e: any) => void
    onMouseLeave: (e: any) => void
}

export const RModalImages: React.FC<IRModalImagesProps> = ({ className, small, smallSrcSet, medium, large, alt, title, hideDownloadButton, hideZoomButton, hideRotateButton, imageBackgroundColor, onMouseEnter, onMouseMove, onMouseLeave }) => {
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            {
                <Image
                    className={`mw-100  mh-100  cursor--pointer  ${className}`}
                    onClick={toggleModal}
                    src={small}
                    alt={alt ?? ''}
                    onMouseEnter={onMouseEnter}
                    onMouseMove={onMouseMove}
                    onMouseLeave={onMouseLeave}
                    sizes={'(min-width: 792px) 660px, 375px'} 
                    fill 
                    priority
                />
            }
            {isModalOpen && (
                <ModalImage
                    medium={medium}
                    large={large}
                    alt={alt}
                    onClose={toggleModal}
                    hideDownloadButton={hideDownloadButton}
                    hideZoomButton={hideZoomButton}
                    hideRotateButton={hideRotateButton}
                    imageBackgroundColor={imageBackgroundColor}
                />
            )}
        </>
    );
};
