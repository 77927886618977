'use client'

import { useProductTableContext } from "@context/product-table"

const ProductVariantsTableSum = () => {
    const { total } = useProductTableContext()

    let formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', });

    return (
        <>{formatter.format(total ?? 0)}</>
    )
}

export default ProductVariantsTableSum
