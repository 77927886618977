'use client'

import type { FacebookProps } from "."

import { useEffect, useState } from "react";
import { fbEvent } from '@rivercode/facebook-conversion-api-nextjs';

const Facebook = ({ ntmData }: FacebookProps): JSX.Element | null => {
    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        const syncConsent = (event?: any) => {
            let w: any = window
            let consent: any = null

            const { CookieFirst }: any = w

            if (event) {
                consent = event ? event.detail || {} : {};
            } else if (CookieFirst && CookieFirst.consent) {
                consent = CookieFirst.consent;
            }

            if (true === consent?.advertising) {
                fbEvent({
                    enableStandardPixel: false,
                    ...(process.env.NEXT_PUBLIC_FB_TEST_EVENT_CODE && { testEventCode: process.env.NEXT_PUBLIC_FB_TEST_EVENT_CODE }),
                    ...ntmData
                });
                setLoaded(true);
            }
        }

        if (false === loaded) {
            syncConsent()
        }

        window.addEventListener("cf_consent", syncConsent);
        window.addEventListener("cf_consent_loaded", syncConsent);

        return () => {
            window.removeEventListener("cf_consent", syncConsent);
            window.removeEventListener("cf_consent_loaded", syncConsent);
        }
    });

    return <></>
}

export default Facebook
