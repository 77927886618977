'use client'

import { useProductMediaContext } from "@context/product-media"
import Image from "./cover/image";
import Video from "./cover/video";

const Cover = () => {
    const { active } = useProductMediaContext()

    const { type } = active

    // eslint-disable-next-line jsx-a11y/alt-text
    return 'image' === type ? <Image /> : <Video />
}

export default Cover
