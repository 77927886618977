'use client'

const MoreInformation = ({ label }: { label: String }) => (
    <div
        className={'fs-6  text-secondary  text-decoration-underline  mb-2  cursor--pointer'}
        onClick={() => {
            const elem = document.getElementById('product_information') ?? document.getElementById('product_properties');
            elem?.scrollIntoView({ block: 'start' })
        }}
    >
        {label}
    </div>
)

export default MoreInformation
