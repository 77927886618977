'use client'

import { createContext, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"

const ProductTableContext = createContext<any>(undefined)

type Product = { id: string, total: number, quantity: number, minPurchase: number, maxPurchase: number, purchaseUnit: number, referenceUnit: number, name: string, prices: { unitPrice: number, quantity: number }[] }

export function useProductTableContext() {
    return useContext(ProductTableContext)
}

const ProductTableProvider = ({ children, products: propProducts }: { children: React.ReactNode, products: { [key: string]: Product } }) => {
    let [total, setTotal] = useState(0)
    let [products, setProducts] = useState<{ [key: string]: Product }>({})

    useEffect(() => setProducts(propProducts), [propProducts])

    useEffect(() => {
        setTotal(total => Object.keys(products).map((key) => products[key]).reduce((acc, cur) => acc + cur.total, 0))
    }, [products])

    const setAmount = (productId: string, newAmount: number) => {
        const product = products[productId]

        if (undefined === product || product?.total === newAmount) return
        let amount = Math.max(newAmount, 0)

        if (0 !== (amount * product.purchaseUnit) % product.purchaseUnit) {
            toast.info('Ihre eingegebene Menge wurde auf die nächstmögliche Bestellmenge angepasst.')
            amount = Math.ceil(amount)
        }
        if (product.maxPurchase && (amount) > product.maxPurchase) {
            toast.info('Ihre eingegebene Menge überschreitet die maximale Bestellmenge und wurde herabgesetzt.')
            amount = Math.floor(product.maxPurchase)
        }

        let newCalculatedPrice = product.prices[product.prices.length - 1];

        for (let i = product.prices.length - 1; 0 <= i; i--) {
            if (product.prices[i].quantity >= amount) {
                newCalculatedPrice = product.prices[i];
            }
        }

        setProducts(products => ({ ...products, [productId]: { ...product, quantity: amount, total: amount * newCalculatedPrice.unitPrice } }))
    }

    return (
        <ProductTableContext.Provider value={{
            total,
            setTotal,

            products,
            setProducts,

            setAmount
        }}>
            {children}
        </ProductTableContext.Provider>
    )
}

export default ProductTableProvider
