'use client'

import { useEffect } from "react"
import { CookieFirstProps } from "."

const CookieFirst = ({ }: CookieFirstProps) => {

    useEffect(() => {
        const { CookieFirst }: any = window
        if (CookieFirst) {
            CookieFirst.renderEmbeds()
        }
    }, [])


    return null
}

export default CookieFirst
