// src/components/molecules/cmscollapse/CmsCollapse.tsx
'use client'

import type { CmsCollapseCardProps } from '.';
import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

const CmsCollapseHeading = ({ component, children }: PropsWithChildren<{ component?: string }>) => {

    if (component && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(component)) {
        return React.createElement(component, {}, children)
    }
    return <>{children}</>
}


const CmsCollapseCard: FC<CmsCollapseCardProps> = ({
    id, typeLabel, labelCollapsedArea, contentCollapsedArea, columnSpanCollapsedArea, columnCountCollapsedArea, columnRuleCollapsedArea, hidden,
    borderCollapsedArea, borderColorCollapsedArea, truncateLabelCollapsedArea, completeLabelingClickable, fontColorLabel, backgroundColorLabel,
    paddingCollapsedAreas, fontColorCollapsedAreas, backgroundColorCollapsedAreas, paddingLabelCollapsedArea, columnRuleColorCollapsedAreas, showCollapsedAreaIcon, collapsedAreaIcon, iconColorLabel,
    onClick
}: CmsCollapseCardProps) => {

    return (
        <div
            key={`cms-element-cmscollapse-accordion-card`}
            className={classNames('cms-element-cmscollapse-accordion-card', { 'is--complete-clickable': true === completeLabelingClickable, [`card-${id}`]: id })}
            style={{
                ...((true === borderCollapsedArea && '' !== borderColorCollapsedArea) && { border: `1px solid ${borderColorCollapsedArea}` })
            }}
        >
            <div
                className={'card-header'}
                style={{
                    ...((true === borderCollapsedArea && '' !== borderColorCollapsedArea) && { border: `1px solid ${borderColorCollapsedArea}` }),
                    ...((true === completeLabelingClickable && true !== truncateLabelCollapsedArea) && { whiteSpace: 'normal' }),
                    ...(('' !== backgroundColorLabel) && { backgroundColor: backgroundColorLabel }),
                    ...((false === completeLabelingClickable && paddingLabelCollapsedArea && !isNaN(paddingLabelCollapsedArea)) && { padding: paddingLabelCollapsedArea }),
                }}
            >
                <div className="mb-0" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                    <CmsCollapseHeading component={typeLabel}>
                        <a
                            className={classNames('btn btn-link d-flex justify-content-between text-decoration-none fs-7', { 'collapsed': hidden })}
                            data-toggle="collapse"
                            aria-expanded={!hidden}
                            aria-controls="collapse"
                            itemProp="name"
                            style={{
                                ...(('' !== fontColorLabel) && { color: fontColorLabel }),
                                ...((true === completeLabelingClickable && paddingLabelCollapsedArea && !isNaN(paddingLabelCollapsedArea)) && { padding: paddingLabelCollapsedArea }),
                            }}
                            onClick={() => onClick?.()}
                        >
                            {labelCollapsedArea}
                            {true === showCollapsedAreaIcon && (
                                'downup' === collapsedAreaIcon ? (
                                    <>
                                        <span className={'sw-icon icon--small-arrow-medium-down sw-icon--fill'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
                                                <defs>
                                                    <path id="icons-small-arrow-medium-down-a" d="M4.70710678,6.29289322 C4.31658249,5.90236893 3.68341751,5.90236893 3.29289322,6.29289322 C2.90236893,6.68341751 2.90236893,7.31658249 3.29289322,7.70710678 L7.29289322,11.7071068 C7.68341751,12.0976311 8.31658249,12.0976311 8.70710678,11.7071068 L12.7071068,7.70710678 C13.0976311,7.31658249 13.0976311,6.68341751 12.7071068,6.29289322 C12.3165825,5.90236893 11.6834175,5.90236893 11.2928932,6.29289322 L8,9.58578644 L4.70710678,6.29289322 Z"></path>
                                                </defs>
                                                <use fill={'undefined' !== typeof iconColorLabel && '' !== iconColorLabel ? iconColorLabel : '#758CA3'} fillRule="evenodd" xlinkHref="#icons-small-arrow-medium-down-a"></use>
                                            </svg>
                                        </span>
                                        <span className={'sw-icon icon--small-arrow-medium-up sw-icon--fill'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
                                                <defs>
                                                    <path id="icons-small-arrow-medium-up-a" d="M4.70710678,4.29289322 C4.31658249,3.90236893 3.68341751,3.90236893 3.29289322,4.29289322 C2.90236893,4.68341751 2.90236893,5.31658249 3.29289322,5.70710678 L7.29289322,9.70710678 C7.68341751,10.0976311 8.31658249,10.0976311 8.70710678,9.70710678 L12.7071068,5.70710678 C13.0976311,5.31658249 13.0976311,4.68341751 12.7071068,4.29289322 C12.3165825,3.90236893 11.6834175,3.90236893 11.2928932,4.29289322 L8,7.58578644 L4.70710678,4.29289322 Z"></path>
                                                </defs>
                                                <use fill={'undefined' !== typeof iconColorLabel && '' !== iconColorLabel ? iconColorLabel : '#758CA3'} fillRule="evenodd" transform="matrix(1 0 0 -1 0 14)" xlinkHref="#icons-small-arrow-medium-up-a"></use>
                                            </svg>
                                        </span>
                                    </>
                                ) : 'plusminus' === collapsedAreaIcon ? (
                                    <>
                                        <span className={'sw-icon icon--small-default-minus-medium sw-icon--fill'}>
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
                                                <defs>
                                                    <path id="icons-small-default-minus-medium-a" d="M2.85714286,9 L13.1428571,9 C13.6162441,9 14,8.55228475 14,8 C14,7.44771525 13.6162441,7 13.1428571,7 L2.85714286,7 C2.38375593,7 2,7.44771525 2,8 C2,8.55228475 2.38375593,9 2.85714286,9 Z"></path>
                                                </defs>
                                                <use fill={'undefined' !== typeof iconColorLabel && '' !== iconColorLabel ? iconColorLabel : '#4a545b'} fillRule="evenodd" xlinkHref="#icons-small-default-minus-medium-a"></use>
                                            </svg>
                                        </span>
                                        <span className={'sw-icon icon--small-default-plus-line-medium sw-icon--fill'} style={{ ...('' !== iconColorLabel && { fill: iconColorLabel }) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style={{ ...('' !== iconColorLabel && { fill: iconColorLabel }) }}>
                                                <path fill={'undefined' !== typeof iconColorLabel && '' !== iconColorLabel ? iconColorLabel : '#4a545b'} fillRule="evenodd" d="M7,7 L7,3 C7,2.44771525 7.44771525,2 8,2 C8.55228475,2 9,2.44771525 9,3 L9,7 L13,7 C13.5522847,7 14,7.44771525 14,8 C14,8.55228475 13.5522847,9 13,9 L9,9 L9,13 C9,13.5522847 8.55228475,14 8,14 C7.44771525,14 7,13.5522847 7,13 L7,9 L3,9 C2.44771525,9 2,8.55228475 2,8 C2,7.44771525 2.44771525,7 3,7 L7,7 Z"></path>
                                            </svg>
                                        </span>
                                    </>
                                )
                                    : null
                            )}
                        </a>
                    </CmsCollapseHeading>
                </div>
            </div >
            <div className={classNames('collapse d-block overflow-hidden', { 'show': !hidden })} itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                <div
                    className={classNames(`card-body column-count-${columnCountCollapsedArea}`, {
                        'has--column-span': true === columnSpanCollapsedArea,
                        'has--column-rule': true === columnRuleCollapsedArea
                    })}
                    itemProp="text"
                    dangerouslySetInnerHTML={{ __html: contentCollapsedArea ?? '' }}
                    style={{
                        ...((paddingCollapsedAreas && !isNaN(paddingCollapsedAreas)) && { padding: paddingCollapsedAreas }),
                        ...(('' !== fontColorCollapsedAreas) && { color: fontColorCollapsedAreas }),
                        ...(('' !== backgroundColorCollapsedAreas) && { backgroundColor: backgroundColorCollapsedAreas }),
                        ...(('' !== columnRuleColorCollapsedAreas && true === columnRuleCollapsedArea) && { columnRuleColor: columnRuleColorCollapsedAreas })
                    }}
                />
            </div>
        </div >
    );
};

export default CmsCollapseCard;
