'use client'

import formatPrice from "@utils/format-price";
import AddToCart from '@components/molecules/block/renderer/product-details/variants-table/add-to-cart';
import { useProductTableContext } from "@context/product-table"
import { useLocale } from "next-intl";

const RowSum = ({ productId, taxId, columnHeader }: { productId: string, columnHeader: string, taxId: string }) => {
    const locale = useLocale()
    const { products } = useProductTableContext()

    return (
        <td data-columnheader={columnHeader} role={'cell'} className={'text-start  text-xl-center  fw-bold'}>
            <span className={'d-inline-flex  gap-2  justify-content-between  align-items-top'}>
                {formatPrice({ value: products[productId]?.total ?? 0, locale, currencyId: 'de-CH' === locale ? 'CHF' : 'EUR' })}
                <span className={'d-inline-block  d-xl-none'}>
                    <AddToCart productId={productId} variant={'extended'} />
                </span>
            </span>
        </td>
    )
}

export default RowSum
