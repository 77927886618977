'use client'

import Button from "@components/atoms/button"

import { useProductTableContext } from "@context/product-table"

import { handleAddAllToCart } from "@app/actions"
import { useCartContext } from "@context/cart-provider"

const AddAllToCartButton = () => {
    const { setLoading, setCart, setShowOffcanvas } = useCartContext()
    const { products, setAmount } = useProductTableContext()

    const activeProducts = Object.keys(products).map((key) => ({
        id: key, referencedId: key, quantity: products[key].quantity, type: 'product'
    })).filter(({ quantity }: { quantity: number }) => 0 < quantity)

    const handleClick = async () => {
        setLoading(true)
        await handleAddAllToCart(activeProducts).then(cart => {
            setCart(cart)
            setLoading(false)
            setShowOffcanvas(true)
            activeProducts.forEach(({ id }: { id: string }) => setAmount(id, 0))
        })
    }

    return (
        <Button variant={'secondary'} icon={'cart__buy'} className={'gap-2 px-4  py-2  mt-3  mb-5'} onClick={handleClick} disabled={0 === activeProducts.length}>{'In den Warenkorb'}</Button>
    )
}

export default AddAllToCartButton
