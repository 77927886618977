'use client'

import useQueryParams from "@hooks/useQueryParams";
import { useState } from "react"

const Sorter = ({ value, label, availableSortings }: { value: string, label: string, availableSortings: { key: string, translated: { label: string } }[] }) => {
    const { setQueryParam } = useQueryParams();

    const [active, setActive] = useState<string>(value)

    const handleChange = (sorting: string) => {
        setQueryParam('sorting', sorting);
        setActive(sorting)
    }

    return (
        <div className={'d-flex  gap-3  align-items-end  w-100  w-md-auto'}>
            <label htmlFor={'category_sorter'} className={'form-label  d-none  d-md-inline-block'}>{label}</label>
            <select id={'category_sorter'} className={'catalog-sort-select  form-select   border-dark  rounded-0  bg-gray-100  fw-semibold'} value={active} onChange={(e) => handleChange(e.currentTarget.value)} aria-label={label}>
                {availableSortings?.map(({ key, translated: { label } }) => (
                    <option key={key} value={key} className={'fw-normal  ff-base'}>{'ki-custom-sorting' === key ? 'TransPak' : label}</option>
                ))}
            </select>
        </div>
    )
}

export default Sorter

