import React from 'react';
import { SpinnerIcon } from './Icons';
import ImageComponent from '@components/atoms/image'

interface IImageProps {
    id: string;
    className: string;
    alt: string
    src: string;
    contextMenu?: boolean;
    handleDoubleClick: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}

export const Image: React.FC<IImageProps> = ({ id, alt, className, src, contextMenu, handleDoubleClick }) => {
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleOnLoad = () => {
        setLoading(true);
    };

    const handleOnContextMenu = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        if (!contextMenu) event.preventDefault();
    };

    return (
        <div>
            {!loading && <SpinnerIcon />}
            <ImageComponent id={id} className={className} src={src} alt={alt} onLoad={handleOnLoad} onDoubleClick={handleDoubleClick} onContextMenu={handleOnContextMenu} fill={true}
            />
        </div>
    );
};
