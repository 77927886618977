'use client'

import React from "react";

import { useProductPurchaseContext } from "@context/product-purchase";

const QtyRemoveButton = ({ children }: { children: React.ReactElement }) => {

    const { product, amount, setAmount, checkAmount } = useProductPurchaseContext()

    if (undefined === product) {
        return (
            <>{children}</>
        )
    }

    const { minPurchase } = product

    return (
        <>
            {React.cloneElement(children, {
                disabled: (!!minPurchase && amount < minPurchase) || amount <= 1,
                onClick: () => setAmount(checkAmount(amount - 1))
            })}
        </>
    )
}

export default QtyRemoveButton
