'use client'

import AddToCart from "@components/molecules/add-to-cart/AddToCart"
import { useCartContext } from "@context/cart-provider"
import { useProductTableContext } from "@context/product-table"

const AddToCartButton = ({ productId, variant }: { productId: string, variant?: 'small' | 'extended' }) => {
    const { setShowOffcanvas } = useCartContext()
    const { products, setAmount } = useProductTableContext()

    const product = products[productId] ?? null

    return (
        <AddToCart
            quantity={product?.quantity ?? 0}
            productId={productId}
            parentId={productId}
            productName={product?.name}
            purchaseUnit={product?.purchaseUnit}
            referenceUnit={product?.referenceUnit}
            variant={variant ?? 'small'}
            label={'In den Warenkorb'}
            disabled={product?.minPurchase > product?.quantity || (product?.maxPurchase && product?.maxPurchase < product?.quantity)}
            {...('extended' === variant && { className: 'icon  icon--cart__buy  icon--white' })}
            callBack={() => {
                setShowOffcanvas(true)
                setAmount(productId, 0)
            }}
        />
    )
}

export default AddToCartButton
