'use client'

import AddToCartButton from "@/components/molecules/add-to-cart"
import { useProductPurchaseContext } from "@context/product-purchase"

const AddToCart = () => {
    const { product, amount } = useProductPurchaseContext()

    let productId = ''
    let parentId = null
    let name = undefined

    if( product) {
         productId = product.id
         parentId =  product.parentId
         name =  product.translated?.name ?? product.name
    }

    return (
        <AddToCartButton productId={productId} parentId={parentId} quantity={amount} productName={name} label={'In den Warenkorb'} variant={'extended'} className={'justify-content-center  py-4'} />
    )
}

export default AddToCart
