'use client'

import type { BackgroundProps } from ".."

import Image from "@components/atoms/image"

const Background = ({ background, headline, priority = true }: BackgroundProps) => {

    const url = new URL(background.replace('url(', '').replace(')', '').replace(';', ''))
    url.host = process.env.NEXT_PUBLIC_CLOUDINARY_DOMAIN ?? url.host

    const pathname = 'https://' + process.env.NEXT_PUBLIC_CLOUDINARY_DOMAIN + url.pathname

    return (
        <picture>
            <source media={`(max-width: 567px)`} srcSet={`${pathname}?tx=w_567,c_fit,q_auto`} />
            <source media={`(max-width: 768px)`} srcSet={`${pathname}?tx=w_768,c_fit,q_auto`} />
            <source media={`(max-width: 992px)`} srcSet={`${pathname}?tx=w_992,c_fit,q_auto`} />
            <source media={`(max-width: 1200px)`} srcSet={`${pathname}?tx=w_1200,c_fit,q_auto`} />
            <source media={`(max-width: 1400px)`} srcSet={`${pathname}?tx=w_1400,c_fit,q_auto`} />
            <source media={`(max-width: 1920px)`} srcSet={`${pathname}?tx=w_1920,c_fit,q_auto`} />
            <source media={`(max-width: 2560px)`} srcSet={`${pathname}?tx=w_2560,c_fit,q_auto`} />
            <source media={`(min-width: 2560px)`} srcSet={`${pathname}`} />

            <Image src={`${url.href}?tx=w_567,c_fit,q_auto`} alt={headline} className={'object-fit-cover'} priority={priority} unoptimized fill />
        </picture>
    )
}

export default Background
