'use client'

import type { NewsletterSubscribeProps } from '.';

import { useState, useRef, Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Form from '@components/atoms/form';
import FormGroup from '@components/molecules/form/form-group';
import FormControl from '@components/atoms/form-control';
import FormCheck from '@components/atoms/form-check';
import FormOption from '@components/atoms/form-option'
import ModalCMS from '@components/molecules/modal/ModalCMS';
import Button from '@components/atoms/button';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const NewsletterSubscribe = ({ onSubmit = () => { }, validationSchema = {}, loading = false, sent = false, confirmationText, title }: NewsletterSubscribeProps) => {

    const [token, setToken] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false)

    const captchaRef = useRef<HCaptcha>(null);

    const t = (key: string) => key

    const onExpire = () => {
        console.log("hCaptcha Token Expired");
    };

    const onError = (err: any) => {
        console.log(`hCaptcha Error: ${err}`);
    };

    const onSuccess = (err: any) => {
        setSuccess(true)
    };

    return (
        <>
            {title && <span className={'d-block  fw-bold'}>{title}</span>}
            <Container fluid className={'px-0'}>
                <div className={'row'}>
                    <div className={'col  col-12  py-3'}>
                        {false === success ? (
                            <Form
                                onSubmit={(values: any, rest?: any) => {
                                    captchaRef.current?.execute();
                                    const v = Object.entries(values).reduce((a: any, [k, v]: any) => (v ? (a[k] = v, a) : a), {})

                                    try {
                                        onSubmit({ ...v, onSuccess, captcha: token }, rest)
                                    } catch (err) {
                                        console.log(err);
                                    } finally {
                                        captchaRef.current?.resetCaptcha();
                                    }

                                }}
                                validationSchema={validationSchema}
                                initialValues={{ email: '', company: '', salutationId: '', firstName: '', lastName: '', zipCode: '', datenschutz: false }}
                            >
                                <FormGroup>
                                    <FormControl id={'email'} className={'fs-8  py-2  mb-2  form-control'} placeholder={`${t(/*'email'*/ 'E-Mail Adresse')} *`} required />
                                </FormGroup>

                                <FormGroup>
                                    <FormControl id={'company'} className={'fs-8  py-2  mb-2  form-control'} placeholder={`${t(/*'company'*/ 'Firma')} *`} required />
                                </FormGroup>

                                <FormGroup className={'d-flex  flex-column  flex-sm-row'}>
                                    <div className={'w-100  w-sm-auto  fs-8   mb-2'}>
                                        <FormControl id="salutationId" as="select" bsPrefix="form-select" className={'fs-8  py-2  form-control'} >
                                            <FormOption value="3e737b85905140e2a9f873d4b9b3ef0a">keine Angabe</FormOption>
                                            <FormOption value="76f477a460e548e4aa5c8d505335bae5">Frau</FormOption>
                                            <FormOption value="0da5d6aae9114886ba3e991baee16d61">Herr</FormOption>
                                            <FormOption value="788a82f434e24dee885a15155ed5a7a8">Divers</FormOption>
                                        </FormControl>
                                    </div>
                                    <div className={'w-100  flex-grow-1  ms-0  ms-sm-2  mb-2'}>
                                        <FormControl id={'firstName'} className={' fs-8  py-2  form-control'} placeholder={`${t(/*'first_name'*/ 'Vorname')} *`} required />
                                    </div>
                                    <div className={'w-100  flex-grow-1  ms-0  ms-sm-2  mb-2'}>
                                        <FormControl id={'lastName'} className={'fs-8  py-2 form-control'} placeholder={`${t(/*'last_name'*/ 'Nachname')} *`} required />
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <FormControl id={'zipCode'} className={'fs-8  py-2  mb-2  form-control'} placeholder={`${t(/*'zipcode'*/ 'Postleitzahl')} *`} />
                                </FormGroup>

                                <FormGroup>
                                    <FormCheck
                                        type={'checkbox'}
                                        id={'datenschutz'}
                                        label={(<>
                                            {'Ich habe die '}<ModalCMS title={'Datenschutzbestimmungen'} buttonText={'Datenschutzbestimmungen '} cmsPageId={process.env.NEXT_PUBLIC_PRIVACY_POLICY ?? ''} />{' zur Kenntnis genommen.'}
                                        </>)}
                                        className={'d-inline-flex  align-items-center  gap-2  fs-8  py-2  mb-3  mt-2'}
                                        style={{ paddingRight: 25 }}
                                        required
                                    />
                                </FormGroup>

                                <FormGroup className={'d-flex  flex-column  flex-sm-row  justify-content-between  w-100 '}>
                                    {process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY &&
                                        <HCaptcha
                                            ref={captchaRef}
                                            sitekey={process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY}
                                            onVerify={setToken}
                                            onError={onError}
                                            onExpire={onExpire}
                                        />
                                    }
                                    <Button type={'submit'} disabled={loading} centered={true} className={'w-100  ms-0  ms-sm-2  mb-2  text-white'}>{t(/*'register_now'*/ 'Jetzt anmelden')}</Button>
                                </FormGroup>
                            </Form>
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: confirmationText ?? 'Erfolgreich angemeldet.' }}></div>
                        )}
                    </div>
                </div>
            </Container>
        </>
    )
}

export default NewsletterSubscribe
