'use client'

import { default as VideoComponent } from '@components/atoms/video'
import { useProductMediaContext } from '@context/product-media'


const Video = () => {
    const { active } = useProductMediaContext()

    return (
        <div className={'image-box-height position-relative'}>
            <VideoComponent url={active.src} alt={''} privacyInfo={'Hier geht es zum YouTube-Video. Ihre Daten werden beim Anklicken an Google übermittelt.'} />
        </div>
    )
}

export default Video
