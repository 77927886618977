import React from 'react';
import { Header } from './Header';
import { Image } from './Image';
import { react_modal_images__modal_container, react_modal_images__modal_content } from './styles';

interface IModalImageProps {
    medium?: string;
    large: string;
    alt?: string;
    onClose: () => void;
    hideDownloadButton?: boolean;
    hideZoomButton?: boolean;
    hideRotateButton?: boolean;
    imageBackgroundColor?: string;
}

export const ModalImage: React.FC<IModalImageProps> = ({ medium, large, alt, onClose, hideDownloadButton, hideZoomButton, hideRotateButton, imageBackgroundColor = 'black' }) => {
    return (
        <div>
            <div className="react_modal_images__modal_container" style={react_modal_images__modal_container}>
                <div className="react_modal_images__modal_content" style={react_modal_images__modal_content}>
                    {(
                        <Image
                            id="react-modal-image-img"
                            className="react_modal_images__medium_img  position-absolute  top-50  start-50  translate-middle  p-4 overflow-hidden  object-fit-contain  bg-white"
                            src={medium ? medium : large}
                            contextMenu={!medium}
                            alt={alt ?? ''}
                            handleDoubleClick={() => { }}
                        />
                    )}
                </div>

                <Header
                    image={large}
                    alt={alt}
                    zoomed={false}
                    toggleZoom={() => { }}
                    toggleRotate={() => { }}
                    onClose={onClose}
                    enableDownload={!hideDownloadButton}
                    enableZoom={!hideZoomButton}
                    enableRotate={!hideRotateButton}
                />
            </div>
        </div>
    );
};
