'use client'

import React from "react";

import { useProductPurchaseContext } from "@context/product-purchase";

const QtyInput = ({ children }: { children: React.ReactElement }) => {

    const { product, amount, setAmount, checkAmount } = useProductPurchaseContext()

    if (undefined === product) {
        return (
            <>{React.cloneElement(children, { value: '', onChange: () => { } })}</>
        )
    }

    const { purchaseUnit, maxPurchase } = product

    return (
        <>
            {React.cloneElement(children, {
                value: (amount * purchaseUnit).toString(),
                disabled: !!product.maxPurchase && (amount >= product.maxPurchase || 0 >= amount),
                onBlur: (e: any) => setAmount(checkAmount(Number(e.target.value) / purchaseUnit)),
                onChange: (e: any) => {
                    if (maxPurchase && (Number(e.target.value) / purchaseUnit) > maxPurchase) {
                        // toast.info('Ihre eingegebene Menge überschreitet die maximale Bestellmenge und wurde herabgesetzt.')
                        setAmount(maxPurchase)
                    } else {
                        setAmount(Number(e.target.value) / purchaseUnit)
                    }
                }
            })}
        </>
    )
}

export default QtyInput
