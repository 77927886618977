'use client'

import { useProductTableContext } from "@context/product-table"
import React from "react"

const RowPrice = ({ productId, amount, columnHeader, children }: { productId: string, amount: number, columnHeader: string, children: React.ReactNode }) => {
    const { setAmount } = useProductTableContext()

    return (
        <td data-columnheader={columnHeader} role={'cell'} className={'text-start  text-xl-center  cursor--pointer'} onClick={() => setAmount(productId, amount)}>
            {children}
        </td>
    )
}

export default RowPrice
