'use client';

import type { VideoProps } from '.';

import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import Image from '@components/atoms/image'

const ReactPlayer = dynamic(() => import('react-player/youtube'))

const Video = ({ url, alt, privacyInfo }: VideoProps) => {

    const [videoUrl, setVideoUrl] = useState(url);
    const [showVideo, setShowVideo] = useState<boolean>(false);

    useEffect(() => {
        if ('' !== url) return
        setVideoUrl(url)
    }, [url])

    return (url ? (
        showVideo ? (
            <ReactPlayer
                key={'video' + videoUrl}
                url={videoUrl}
                width='100%'
                height='100%'

                config={{ embedOptions: { host: 'https://www.youtube-nocookie.com' } }}
            />
        ) : (
            <>
                <Image
                    src={'/video.png'}
                    alt={alt ?? ''}
                    className={'cursor-pointer bg-gray-200  object-fit-none'}
                    onClick={() => setShowVideo((showVideo: boolean) => !showVideo)}
                    fill
                />
                {privacyInfo && <p className={'position-absolute bottom-0 text-muted fs-9 px-4'}>{privacyInfo}</p>}
            </>
        )) : (
        <></>
    ))
}

export default Video;
