'use client'

import { useProductMediaContext } from "@context/product-media"
import debounce from "debounce";
import { useState } from "react";
import { RModalImages } from './modal-images';

const Image = () => {
    const { active } = useProductMediaContext()

    const zoomLevel = 3

    const [[x, y], setXY] = useState([0, 0]);
    const [[offsetX, offsetY], setOffset] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);

    const debouncedHandleMouseEnter = debounce((elem) => {
        let { width, height } = elem.getBoundingClientRect();

        if (elem.naturalWidth > elem.naturalHeight) {
            setOffset([0, Math.floor((width - (width / (elem.naturalWidth / elem.naturalHeight)))) / 2])
        } else {
            setOffset([Math.floor((height - (height / (elem.naturalHeight / elem.naturalWidth)))) / 2, 0])
        }

        setShowMagnifier(true)
    }, 100)

    const { url, alt, title } = active

    return (
        url ? (
            <>
                <div className={'image-box-height position-relative'}>
                    <RModalImages
                        small={url}
                        medium={url}
                        large={url}
                        alt={alt}
                        title={title}
                        hideDownloadButton={true}
                        hideZoomButton={true}
                        hideRotateButton={true}
                        imageBackgroundColor={'#fff'}
                        onMouseEnter={(e) => {
                            // update image size and turn-on magnifier
                            debouncedHandleMouseEnter(e.currentTarget)
                        }}
                        onMouseMove={(e) => {
                            // update cursor position
                            const elem = e.currentTarget;
                            const { top, left } = elem.getBoundingClientRect();

                            // calculate cursor position on the image
                            const x = e.pageX - left - window.scrollX;
                            const y = e.pageY - top - window.scrollY;
                            setXY([x, y]);
                        }}
                        onMouseLeave={() => {
                            // close magnifier
                            setShowMagnifier(false);
                            debouncedHandleMouseEnter.clear()
                        }}
                        className={'position-absolute  top-50  start-50  translate-middle  object-fit-contain  p-3'}
                    />
                    {showMagnifier && (
                        <div
                            style={{
                                backgroundImage: `url('${url}')`,
                                backgroundRepeat: "no-repeat",

                                backgroundSize: `${100 * zoomLevel}%`,

                                backgroundPositionX: `${-(x - offsetX) * zoomLevel}px`,
                                backgroundPositionY: `${-(y - offsetY) * zoomLevel}px`,
                            }}
                            className={'position-absolute  top-0  start-0   w-100  h-100  opacity-100  pe-none  bg-white'}
                        >
                        </div>
                    )}
                </div>
                <div className={'position-absolute  start-0  bottom-0  mb-2  ms-2  fs-9  text-gray-500'}>
                    Abb. kann v. Original abweichen
                </div>
            </>
        ) : null
    )
}

export default Image
