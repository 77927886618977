'use client'

import { createContext, useContext, useEffect, useState } from "react"

const ProductMediaContext = createContext<any>(undefined)

type Image = any

export function useProductMediaContext() {
    return useContext(ProductMediaContext)
}

const ProductMediaProvider = ({ children, images: propImages }: { children: React.ReactNode, images: Image[] }) => {
    let [active, setActive] = useState<{ [key: string]: Image }>(propImages?.[0])
    let [images, setImages] = useState<Image[]>([])

    useEffect(() => {
        setImages(propImages)
        setActive(propImages[0])
    }, [propImages])

    return (
        <ProductMediaContext.Provider value={{
            active,
            setActive,

            images
        }}>
            {children}
        </ProductMediaContext.Provider>
    )
}

export default ProductMediaProvider
