// components/atoms/no-ssr/NoSsr.tsx

'use client'

import type { NoSsrProps } from "."

import { useEffect } from "react"
import dynamic from "next/dynamic"

const NoSsr = ({ children }: NoSsrProps) => {

    useEffect(() => {
        const { CookieFirst }: any = window
        if (CookieFirst) {
            CookieFirst.renderEmbeds()
        }
    }, [])


    return (
        <>{children}</>
    )
}

export default dynamic(() => Promise.resolve(NoSsr), {
    ssr: false
})
