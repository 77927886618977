'use client'

import { setLastSeenProduct } from "@app/actions"
import { useEffect } from "react"

const SetLastSeen = ({ id }: { id: string }) => {

    useEffect(() => {
        setLastSeenProduct(id)
    }, [id])

    return null
}

export default SetLastSeen
