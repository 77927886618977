'use client'

import Image from "@components/atoms/image"
import { useProductMediaContext } from "@context/product-media"
import { useRef } from "react";

const Preview = () => {
    const ref = useRef<HTMLDivElement>(null);
    const { images, active, setActive } = useProductMediaContext()

    const activeIndex = images.findIndex((element: any) => element === active)

    const handleSetActive = (index: number) => {
        const _index = Math.max(0, Math.min(index, images.length - 1))

        const element = ref.current?.children.item(index)
        element?.scrollIntoView({ block: 'nearest', inline: 'end', behavior: 'smooth' })

        setActive(images[_index])
    }

    return (
        <div className={'position-relative  mt-2  px-5  overflow-hidden'}>
            <span
                className={`position-absolute  start-0  top-0  d-flex  h-100  icon  icon--chevron-left  align-items-center  bg-white  bg-gradient  px-2  cursor--pointer ${0 === activeIndex ? 'icon--gray-500' : ''}`}
                onClick={() => handleSetActive(activeIndex - 1)}
            ></span>
            <div ref={ref} className={'position-relative  d-flex  gap-2  overflow-hidden  w-100'}>
                {images?.map((image: any, index: number | string) => (
                    <div className={`cursor--pointer  border${index === activeIndex ? ' border-secondary' : ''}`} key={`preview_image_${index}`} onClick={() => setActive(image)}>
                        <Image className={'object-fit-contain'} src={image?.url ?? '/no-image.png'} alt={image?.alt} key={`${index}`} width={71} height={71} />
                    </div>
                ))}
            </div>
            <span
                className={`position-absolute  end-0  top-0  d-flex  h-100  icon  icon--chevron-right  align-items-center  bg-white  bg-gradient  px-2  cursor--pointer ${images.length - 1 === activeIndex ? 'icon--gray-500' : ''}`}
                onClick={() => handleSetActive(activeIndex + 1)}
            ></span>
        </div>
    )
}

export default Preview
