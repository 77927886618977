'use client'

import type { SimptrackProps } from "."

import { useEffect, useState } from "react";

const Simptrack = ({ ntmData }: SimptrackProps): JSX.Element | null => {
    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        const syncConsent = (event?: any) => {
            let w: any = window
            let consent: any = null

            const { CookieFirst }: any = w

            if (event) {
                consent = event ? event.detail || {} : {};
            } else if (CookieFirst && CookieFirst.consent) {
                consent = CookieFirst.consent;
            }

            if (true === consent?.necessary) {
                w.ntmData = [] // w.ntmData || [];
                w.ntmData.push(ntmData);
                const call = function (n: any, e: any, o: any, r: any, y: any) { n[r] = n[r] || []; n[r].push({ 'event': 'ntmInit', 't': new Date().getTime() }); var f = e.getElementsByTagName(o)[0], s = e.createElement(o), d = r != 'ntmData' ? '&ntmData=' + r : ''; s.async = true; s.src = 'http' + (document.location.protocol == 'https:' ? 's' : '') + '://tm.simptrack.com/tm/a/container/init/' + y + '.js?' + d + '&rnd=' + Math.floor(Math.random() * 100000000); f.parentNode.insertBefore(s, f); };
                call(w, document, 'script', 'ntmData', 'a874e048af')
                setLoaded(true);
            }
        }

        if (false === loaded) {
            syncConsent()
        }

        window.addEventListener("cf_consent", syncConsent);
        window.addEventListener("cf_consent_loaded", syncConsent);

        return () => {
            window.removeEventListener("cf_consent", syncConsent);
            window.removeEventListener("cf_consent_loaded", syncConsent);
        }
    });

    return <></>
}

export default Simptrack
