'use client'

import { useProductTableContext } from "@context/product-table"
import React from 'react';

const RowPrice = ({ productId, qty, children }: { productId: string, qty: number, children: React.ReactNode }) => {
    const { setAmount } = useProductTableContext()

    return (
        <div className={'swiper-slider  d-inline-flex  flex-column  justify-content-center  py-1 cursor--pointer'} onClick={() => setAmount(productId, qty)}>
            {children}
        </div>
    )
}

export default RowPrice
