// src/components/molecules/cmscollapse/CmsCollapseWrapper.tsx

'use client'

import React, { Children, cloneElement, useState } from 'react';

const CmsCollapseWrapper = ({ children }: { children: React.ReactNode }) => {
    const [visibleIndex, setVisibleIndex] = useState<number | undefined>()

    return (
        Children.map(children, (child: any, index: number) => {
            if (React.isValidElement(child as any)) {
                if ('string' !== typeof child && 'number' !== typeof child && 'boolean' !== typeof child) {
                    return cloneElement(child as any, ({
                        hidden: !(index === visibleIndex || (!!(child?.props.oneAreaIsCollapsed) && undefined == visibleIndex && ('undefined' !== typeof child?.props.collapsedArea.value && !isNaN(parseInt(child?.props.collapsedArea.value?.toString())) && index === parseInt(child?.props.collapsedArea.value?.toString()) - 1))),
                        onClick: () => setVisibleIndex(visibleIndex !== index ? index : -1)
                    }))
                }
            }
            return child
        })
    )
}

export default CmsCollapseWrapper
