'use client'

import Button from "@components/atoms/button"
import useQueryParams from "@hooks/useQueryParams"
import debounce from "debounce";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";

const ShowVariantTableButton = ({ label }: { label: string }) => {
    const searchParams = useSearchParams()

    const { setQueryParam, queryParams } = useQueryParams();

    const showTable: boolean = '1' === searchParams.get('show_variant_table')

    useEffect(() => {
        if (true === showTable) {
            const scroll = debounce(function () {
                const elem: HTMLCollectionOf<any> | undefined = document.getElementsByClassName('table-product__variant');
                window.scrollTo({ top: Math.max(0, (elem?.[0]?.offsetTop ?? 0) - 130), behavior: 'smooth', });
            }, 200);

            scroll()
        }
    }, [showTable])

    const handleClick = () => setQueryParam('show_variant_table', !queryParams.get('show_variant_table') ? '1' : null);

    return (
        <Button variant={'secondary'} icon={'chevron-right'} iconVariant={'white'} iconWidth={'sm'} onClick={handleClick} className={'w-100   w-lg-auto  justify-content-center  mt-3  py-3  px-1  px-md-3  px-xl-5'}>{label}</Button>
    )
}

export default ShowVariantTableButton
